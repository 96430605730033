.rdrSelected {
  background-color: #2648e8;
}

.rdrDayStartPreview.rdrDayEndPreview {
  color: #637381 !important;
}

.rdrDayNumber::after {
  color: #637381;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #2648e8;
}
