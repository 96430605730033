/* .rdrDayNumber > span {
  color: black !important;
}
 */
.rdrDayPassive > .rdrDayNumber > span {
  color: rgb(213, 220, 224) !important;
}

.rdrCalendarWrapper,
.rdrMonth {
  width: 100%;
}

/* Selectable day color */
.rdrDayNumber span {
  color: #000 !important;
}

/* Color of today's line  */
.rdrDayToday .rdrDayNumber span::after {
  background-color: #2648e8 !important;
}
