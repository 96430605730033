.title {
  color: blue;
  font-family: 'Source Sans Pro', 'SourceSansPro-Bold';
  font-weight: 700;
  font-size: 34px;
  line-height: 128%;
  letter-spacing: 0px;
  text-align: center;
}

.message {
  padding: 40px;
}

.message ul,
.message ol {
  padding-left: 40px;
}

.message li {
  height: 22px;
}

.message p,
.message ul,
.message ol {
  margin: 14px 0;
}

@media print {
  .document {
    margin-top: 40px;
  }
}

.document {
  width: 100%;
}

.date,
.col-12 > p {
  margin-bottom: 16px;
}

.document > h2,
.contentDocument h2 {
  font-size: 34px;
  font-weight: 700;
  line-height: 128%;
  letter-spacing: 0;
  color: #0c1a86;
  text-align: center;
  font-family: 'Source Sans Pro', 'SourceSansPro-Bold';
  padding-bottom: 8px;
}

.variable,
.text-left {
  line-height: 24px;
}

.message,
.cuerpo {
  padding: 55px 55px 110px 55px;
  line-height: 20px;
  font-family: 'Source Sans Pro', 'SourceSansPro-Bold';
}

.document ul,
.document ol {
  padding-left: 40px;
  margin: 14px 0;
}

.message p {
  margin: 14px 0;
}

.document li {
  line-height: 22px;
}

.signContainer,
.firmado {
  padding: 10px 10px 110px 10px;
  display: flex;
  justify-content: space-around;
}

.sign,
.firma-digital {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sign > p,
.firmado strong {
  padding: 0 80px;
  display: flex;
  justify-content: center;
  font-family: 'Source Sans Pro', 'SourceSansPro-Bold';
  font-weight: 700;
  font-size: 18px;
}

.firmaEmp {
  overflow-wrap: anywhere;
  max-width: 50%;
}

.main > p {
  margin-top: 16px;
}

.document {
  background-color: white;
  min-height: 11in;
  height: auto;
  margin: 0 auto;
  padding: 16px;
}

@media (max-width: 600px) {
  .signContainer {
    flex-direction: column;
    padding: 0px;
  }
  .signContainer > *:nth-child(2) {
    margin: 48px 0;
  }
  .message {
    padding: 8px;
    margin-bottom: 32px;
  }
  .sign > p {
    padding: 0px;
    width: 100%;
  }
  .document {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {
  .document {
    width: 100%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .document {
    width: 8.5in;
  }
}
