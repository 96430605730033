.rdrDayPassive > .rdrDayNumber > span {
  color: rgb(213, 220, 224) !important;
}

.rdrMonth {
  width: 100%;
  font-family: public sans;
  padding: 10px 20px 0px 20px;
}

.rdrMonthName {
  display: none;
}

.rdrMonths.rdrMonthsHorizontal > .rdrMonth:first-child {
  border-right: 1px solid rgba(145, 158, 171, 0.24);
}

.custom-day-active {
  color: #2648e8;
}
