.react-pdf__Page {
  margin: 10px;
}

.react-pdf__Page__textContent span {
  opacity: 0.2;
}

.react-pdf__Page__textContent span::selection {
  background-color: rgb(38, 72, 232);
}
