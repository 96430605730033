html {
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

@font-face {
  font-family: 'Gordita Regular';
  font-style: normal;
  font-weight: normal;
  src:
    local('Gordita Regular'),
    url(./components/Typographies/Fonts/Gordita/GorditaRegular.woff)
      format('woff');
}

@font-face {
  font-family: 'Gordita Medium';
  font-style: normal;
  font-weight: normal;
  src:
    local('Gordita Medium'),
    url(./components/Typographies/Fonts/Gordita/GorditaMedium.woff)
      format('woff');
}

@font-face {
  font-family: 'Gordita Bold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Gordita Bold'),
    url(./components/Typographies/Fonts/Gordita/GorditaBold.woff) format('woff');
}

@font-face {
  font-family: 'Be Vietnam';
  font-style: normal;
  font-weight: normal;
  src:
    local('Be Vietnam'),
    url(./components/Typographies/Fonts/BeVietnamPro/BeVietnamProRegular.ttf)
      format('truetype');
}

.hoverable {
  cursor: pointer;
}
.listItemSora {
  color: #637381;
}
.listItemSora.Mui-selected {
  border-right: 2px solid #2c58e3;
  color: #2c58e3;
  background-color: rgba(38, 72, 232, 0.03);
}
.listItemSora.Mui-selected .ListItemTextSora .MuiListItemText-primary {
  font-weight: bold;
  font-family: Karla, SansSerif, serif;
}
.ListItemTextSora .MuiListItemText-primary {
  font-family: Karla, SansSerif, serif;
}

.fullWidth {
  width: 100% !important;
}
#root {
  height: 100%;
}

.bullets {
  opacity: 0.8;
  list-style-type: disc !important;
}

.loader {
  animation: spin 3s linear infinite;
  border-radius: 50%;
  height: auto;
}
.MuiAlert-action {
  align-items: center;
}
.MuiAlert-standardWarning {
  background: #fff6d6;
}
.MuiAlert-standardSuccess {
  background: #cffede;
}
.MuiAlert-root {
  border-radius: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fade {
  position: fixed;
  top: 100%;
  right: 0;
  z-index: 1000;
  animation: fadeout 1.5s;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.categoryNameContainer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: ease-in;
  transition-duration: 200ms;
  border-color: rgba(145, 158, 171, 0.32);
  height: 50px;
  transition-property: padding, border;
}
.categoryNameContainer:hover {
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 8px;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background: #b4bcc3;
  border-radius: 4px;
  height: 16px;
}

*::-webkit-scrollbar-track {
  border-radius: 4px;
}

html *::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
html *::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #dbe0e5;
}
html *::-webkit-scrollbar-thumb:hover {
  background-color: #b4bcc3ff;
  border-radius: 8px;
}

g:focus {
  outline: none;
}

.recharts-layer .recharts-pie-sector {
  outline: none;
}

/* .recharts-surface {
  cursor: pointer;
} */

.recharts-sector {
  outline: none;
}
